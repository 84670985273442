import {Calendar} from "@fullcalendar/core";

$(document).on('turbolinks:load', function () {

    if ($('.tom-select_item_templates').length) {
        itemTemplatesSelect = new TomSelect('.tom-select_item_templates', {
            // create: true,
            closeAfterSelect: true,
            openOnFocus: false, // Pridané nastavenie pre zakázanie automatického otvorenia
            valueField: 'id',
            searchField: ['title', 'ean'],
            options: [],
            render: {
                option: function (data, escape) {
                    var eanHtml = data.ean ? '<br><span class="ean text-muted">' + escape(data.ean) + '</span>' : '';
                    var stockItemClass = data.stock_item ? ' stock-item' : '';
                    return '<div class="' + stockItemClass + '">' +
                        '<span class="title">' + escape(data.title) + '</span>' +
                        eanHtml +
                        '</div>';
                },
                item: function (data, escape) {
                    var eanTitle = data.ean ? ' title="' + escape(data.ean) + '"' : '';
                    return '<div' + eanTitle + '>' + escape(data.title) + '</div>';
                }
            }
        });
    }


    // Add an event listener to the dropdown
    // Declare a variable to store the selected item data
    let selectedItemData = null;

    $('#service_item_template').on('change', function () {
        var serviceItemTemplateId = $(this).val();
        var workshopId = $('#service_item_template').data('workshopId');

        if (serviceItemTemplateId) {
            $.ajax({
                url: '/' + workshopId + '/service_item_templates/' + serviceItemTemplateId,
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (data.record_type === 'stock_item') {
                        // Store the item data in the variable
                        selectedItemData = data;
                        // Set the modal title to the item description
                        $('#stockItemQuantityModalLabel').text(data.description);
                        // Open the modal
                        $('#stockItemQuantityModal').modal('show');
                        // focus to quantity field
                        $('#stock_item_quantity').focus();
                        // console.log('submit disabled ' + $('#submit_quantity').prop('disabled'));
                        $('#submit_quantity').prop('disabled', false);
                    } else {
                        // else is for item templates
                        populateFields(data);

                        itemTemplatesSelect.clear();
                        itemTemplatesSelect.focus();
                    }
                }
            });
        }
    });

    // When the modal is submitted, populate the fields with the stored item data and the entered quantity
    $('#stockItemQuantityModal').on('submit', function (event) {
        event.preventDefault();
        // Get the entered quantity from the modal
        var quantity = $('#stock_item_quantity').val();
        // Update the quantity in the stored item data
        selectedItemData.quantity = quantity;
        // Populate the fields with the updated item data
        populateFields(selectedItemData);

        // Close the modal
        $('#stockItemQuantityModal').modal('hide');
        // console.log('submit disabled ' + $('#submit_quantity').prop('disabled'));
        itemTemplatesSelect.clear();
        itemTemplatesSelect.focus();
    });

    // Function to populate the fields with the given item data
    function populateFields(data) {
        // if data.markup_percentage is not null and not 0.0
        if (data.markup_percentage && data.markup_percentage !== 0.0) {
            // it should check if #car_service_record_use_custom_markup_percentage is not checked
            if (!$('#car_service_record_use_custom_markup_percentage').is(':checked')) {
                // if not checked it should check the checkbox
                $('#car_service_record_use_custom_markup_percentage').prop('checked', true).trigger('change');
            }
        }

        document.querySelector('.add_fields').click();
        $('.nested-fields:last .item-description').val(data.description);
        $('.nested-fields:last .stock-item-id').val(data.id);
        $('.nested-fields:last .item-quantity').val(data.quantity);
        $('.nested-fields:last .item-unit').val(data.unit);
        $('.nested-fields:last .purchase-item-unit-price').val(data.purchase_unit_price);
        $('.nested-fields:last .item-vat-percentage').val(data.vat_percents);
        $('.nested-fields:last .item-unit-price').val(data.unit_price);
        $('.nested-fields:last .item-remote-id').val(data.remote_id);
        $('.nested-fields:last .item-ean').val(data.ean);
        $('.nested-fields:last .item-supplier-code').val(data.supplier_code);

        if (data.markup_percentage && data.markup_percentage !== 0.0) {
            $('.nested-fields:last .item-markup-percentage').val(data.markup_percentage);
        } else {
            var markupPerc = $('.markup-percentage').val();
            $('.nested-fields:last .item-markup-percentage').val(markupPerc);
        }

        var nestedFields = $('.nested-fields:last')
        calculateServiceItem(nestedFields);
        calculateAllItemsAndTotal();

    };
});