// app/javascript/channels/photo_recognition_channel.js
import consumer from "./consumer"

document.addEventListener("turbolinks:load", function () {
    // Initially set recognitionUUID to null
    var recognitionUUID = null;
    var subscription = null;

    // Function to create subscription
    function createSubscription() {
        if (recognitionUUID) {
            console.log("[photo_recognition_channel.js] Creating subscription for recognitionUUID: " + recognitionUUID);

            // Unsubscribe from the previous subscription if it exists
            if (subscription) {
                subscription.unsubscribe();
            }

            // Create a new subscription
            subscription = consumer.subscriptions.create({
                channel: "PhotoRecognitionChannel",
                recognition_uuid: recognitionUUID
            }, {
                connected() {
                    // Called when the subscription is ready for use on the server
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                received(data) {
                    console.log('[photo_recognition_channel.js] Received data: ', data);
                    if (data.error) {
                        $('#photo_for_recognition_error').removeClass("d-none").text(data.error);
                    } else {
                        var car = data;

                        // setting the value of the car name
                        var car_name = [car.model, car.model_type].filter(Boolean).join(" ");
                        console.log('[photo_recognition_channel.js] Setting car name: ' + car_name);

                        window.selFromMainSearch = true;

                        $('.car_name').val(car_name);

                        if (typeof carsNumberPlates !== "undefined") {
                            carsNumberPlates.addOption({number_plate: car.number_plate, name: car.number_plate});
                            carsNumberPlates.addItem(car.number_plate);
                        }

                        carsList.addOption({value: car.model, text: car.model});
                        carsList.setValue(car.model);
                        $('.car_make').val(car.model);

                        carsModelsList.addOption({value: car.model_type, text: car.model_type});
                        carsModelsList.setValue(car.model_type);

                        if ($('#car_number_plate').length) {
                            $('#car_number_plate').val(car.number_plate);
                        }

                        if (car.manufacture_year != null) {
                            $('.manufacture_year').val(car.manufacture_year);
                        } else if (car.first_registration_dat != null) {
                            var year = moment(car.first_registration_dat).format('YYYY');
                            $('.manufacture_year').val(year);
                        } else {
                            $('.manufacture_year').val('');
                        }

                        if ($('.engine_code').length) {
                            $('.engine_code').val(car.engine_code);
                        }

                        if ($('.engine_size').length) {
                            $('.engine_size').val(car.engine_size);
                        }

                        if ($('.engine_power').length) {
                            $('.engine_power').val(car.power);
                        }

                        if ($('.technical_check').length) {
                            if (car.technical_check != null) {
                                var technical_check = moment(car.technical_check).format('DD/MM/YYYY');
                                $('.technical_check').val(technical_check);
                            } else {
                                $('.technical_check').val('');
                            }
                        }

                        if ($('.vin').length) {
                            // if car.vin is present, set the value of the vin field
                            if (car.vin != null) {
                                console.log('[photo_recognition_channel.js] Setting VIN: ' + car.vin);
                                $('.vin').val(car.vin);

                                // not searching vin, vin search is executed on change of vin field
                                // if ($('.vin_search_field').length) {
                                // $('.vin_search_field').val(car.vin);
                                // console.log('[photo_recognition_channel.js] Clicking submit_vin_search');
                                // $('#submit_vin_search').click();
                                // }
                            } else {
                                $('.vin').val('');
                            }
                        }

                        $('#loader').addClass("d-none");
                        window.selFromMainSearch = false;
                    }
                    $('#loader').addClass("d-none");
                }
            });
        }
    }

    // Listen for custom event to set recognitionUUID and create subscription
    document.addEventListener("setRecognitionUUID", function (event) {
        recognitionUUID = event.detail.recognitionUUID;
        createSubscription();
    });
});