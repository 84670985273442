$(document).on('turbolinks:load', function () {
    function calculateValues(changedField) {
        var purchaseUnitPrice = $('.purchase_unit_price').val();
        var unitPrice = $('.unit_price').val();
        var markupPercentage = $('.markup_percentage').val();

        if (purchaseUnitPrice) {
            purchaseUnitPrice = new Big(purchaseUnitPrice);
        }
        if (unitPrice) {
            unitPrice = new Big(unitPrice);
        }
        if (markupPercentage) {
            markupPercentage = new Big(markupPercentage);
        }

        if (changedField === 'purchaseUnitPrice' || changedField === 'unitPrice') {
            if (purchaseUnitPrice && unitPrice) {
                var calculatedMarkupPercentage = unitPrice.minus(purchaseUnitPrice).div(purchaseUnitPrice).times(100);
                $('.markup_percentage').val(calculatedMarkupPercentage.toFixed(2));
            }
        } else if (changedField === 'markupPercentage') {
            if (purchaseUnitPrice && markupPercentage) {
                var calculatedUnitPrice = purchaseUnitPrice.times(markupPercentage.div(100).plus(1));
                $('.unit_price').val(calculatedUnitPrice.toFixed(2));
            } else if (unitPrice && markupPercentage) {
                var calculatedPurchaseUnitPrice = unitPrice.div(markupPercentage.div(100).plus(1));
                $('.purchase_unit_price').val(calculatedPurchaseUnitPrice.toFixed(2));
            }
        }
    }

    $('.purchase_unit_price').on('keyup change', function () {
        calculateValues('purchaseUnitPrice');
    });

    $('.unit_price').on('keyup change', function () {
        calculateValues('unitPrice');
    });

    $('.markup_percentage').on('keyup change', function () {
        calculateValues('markupPercentage');
    });
});