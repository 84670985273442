document.addEventListener("turbolinks:load", function () {

    if ($('#photo_for_recognition_dummy').length) {
        var dummyInput = document.getElementById("photo_for_recognition_dummy");
        var hiddenInput = document.getElementById("photo_for_recognition");
        var carPhotoInput = document.getElementById("car_photo");

        var form = document.getElementById("photo_recognition_form");

        dummyInput.addEventListener("change", function () {
            if (dummyInput.files.length > 0) {
                var file = dummyInput.files[0];

                // Create a new File object to copy the file
                var dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                hiddenInput.files = dataTransfer.files;
                carPhotoInput.files = dataTransfer.files;

                $("#submit-photo-recognition").click();


                $('#photo_for_recognition_error').addClass("d-none");
                $('#photo_for_recognition_error').text("");
                loader.removeClass("d-none");
            }
        });
    }

});
