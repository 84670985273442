document.addEventListener('turbolinks:load', function () {
    $('.extra-option-checkbox').change(function () {
        // Get the service ID from the data attribute
        var serviceId = $(this).data('serviceId');

        var servicePrice = parseFloat($('#service-data-' + serviceId).data('price'));
        var serviceDuration = parseInt($('#service-data-' + serviceId).data('duration'));

        $('.extra-option-checkbox:checked').each(function () {
            // Inside this function, 'this' refers to the current checkbox element
            // var serviceId = $(this).data('serviceId');
            var price = $(this).data('price');
            var duration = $(this).data('duration');

            servicePrice += parseFloat(price);
            serviceDuration += parseInt(duration);
        });

        var totalPriceElement = $('.total-price-' + serviceId);
        var totalDurationElement = $('.total-duration-' + serviceId);

        // Update the total price and duration on the DOM
        totalPriceElement.text(
            Number(servicePrice.toFixed(2)).toLocaleString(wLocale, {
                style: "currency",
                currencyDisplay: "symbol",
                currency: wCurrency,
            })
        );
        // totalDurationElement.text(Math.floor(serviceDuration / 60) + 'h ' + (serviceDuration % 60) + 'm');
        totalDurationElement.text(humanizeDuration(serviceDuration));
    });
});


$(document).on('click', '.book-now-submit-btn', function () {
    var fullId = $(this).attr('id');
    $('.' + fullId).click();
});